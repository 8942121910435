<template>
  <div class="mb-12">
    <v-app-bar flat class="main-appbar" color="transparent">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold">
        Contents
      </v-toolbar-title>
      <!-- end toolbar title -->
    </v-app-bar>
    <!-- end app bar -->
    <div class="pa-4 mb-12">
      <v-simple-table>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="content in contents" :key="content.key">
            <td>{{ content.key }}</td>
            <td>{{ content.value }}</td>
            <td width="200px" align="right">
              <v-btn
                icon
                color="grey lighten-1"
                class="mr-2"
                @click="editContent(content)"
              >
                <v-icon>{{ icons.edit }}</v-icon>
              </v-btn>
              <v-menu max-width="260">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="grey lighten-1" v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.delete }}</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <div class="mb-4 text-subtitle-1">
                      Are you sure you want to delete this item (
                      <b> {{ content.key }} </b>) ?
                    </div>
                    <div class="d-flex justify-space-between">
                      <v-btn depressed>Cancel</v-btn>
                      <v-btn
                        depressed
                        color="error"
                        @click="deleteContent(content)"
                      >
                        Yes, Delete
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-btn
      color="primary"
      id="add-content-button"
      large
      dark
      rounded
      elevation="16"
      @click="openCreateForm"
    >
      <v-icon>
        {{ icons.plus }}
      </v-icon>
      <span class="ml-2">Add Content</span>
    </v-btn>
    <v-dialog v-model="showAddModal" width="400">
      <v-card>
        <v-toolbar>
          <v-toolbar-title v-if="editMode">Edit content</v-toolbar-title>
          <v-toolbar-title v-else>Add new content</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showAddModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-form @submit.prevent="save">
            <label class="text-field-label">Key</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.key"
              :error-messages="form.$getError('key')"
              :disabled="editMode"
            />

            <label class="text-field-label">Value</label>
            <v-textarea
              auto-grow
              flat
              solo
              class="mt-2"
              v-model="form.value"
              :error-messages="form.$getError('value')"
            />

            <div class="d-flex justify-space-between mt-6">
              <v-btn
                depressed
                type="button"
                color="grey lighten-3"
                @click="showAddModal = false"
              >
                Cancel
              </v-btn>
              <v-btn depressed type="submit" color="primary">Submit</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" top>
      Content successfully saved!
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="success = false">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiPlusCircleOutline, mdiDelete, mdiPencil, mdiClose } from '@mdi/js'
import Content from '@/models/Content'
import Form from '@/utils/form'
import { findIndex } from 'lodash'

export default {
  name: 'MissionPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      showAddModal: false,
      isLoading: false,
      contents: [],
      selectedContent: null,
      success: false,
      form: new Form({
        key: '',
        value: ''
      }),
      icons: {
        plus: mdiPlusCircleOutline,
        delete: mdiDelete,
        edit: mdiPencil,
        close: mdiClose
      }
    }
  },

  mounted() {
    this.fetchContents()
  },

  computed: {
    editMode() {
      return !!this.selectedContent
    }
  },

  methods: {
    openCreateForm() {
      this.selectedContent = null
      this.showAddModal = true
      this.form.key = ''
      this.form.value = ''
    },
    editContent(content) {
      this.selectedContent = content
      this.showAddModal = true
      this.form.key = this.selectedContent.key
      this.form.value = this.selectedContent.value
    },
    save() {
      if (this.selectedContent) {
        this.update()
      } else {
        this.create()
      }
    },
    async create() {
      this.form.$clearErrors()
      try {
        const res = await this.$api.post('/contents', this.form.$data())
        this.contents.unshift(new Content(res.data.data))
        this.form.$reset()
        this.success = true
        this.showAddModal = false
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }
      }
    },

    async update() {
      this.form.$clearErrors()
      try {
        const res = await this.$api.put(
          '/contents/' + this.selectedContent.key,
          this.form.$data()
        )
        const content = res.data.data
        const index = findIndex(this.contents, { key: content.key })
        if (index > -1) {
          this.contents.splice(index, 1, new Content(content))
        }
        this.form.$reset()
        this.success = true
        this.showAddModal = false
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.form.$setErrors(error.response.data.errors)
        }
      }
    },
    async fetchContents() {
      this.isLoading = true
      this.contents = await Content.$get()
      this.isLoading = false
    },

    async deleteContent(content) {
      await content.delete()
      const index = findIndex(this.contents, { key: content.key })
      if (index > -1) {
        this.contents.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
#add-content-button {
  position: fixed;
  bottom: 64px;
  right: 64px;
}
</style>
