import Model from './Model'

export default class Content extends Model {
  resource() {
    return 'contents'
  }

  primaryKey() {
    return 'key'
  }
}
